<!-- ################################################################################################################################################################################################################################################ -->
<!-- Failed To Get Practice Information -->
<!-- ################################################################################################################################################################################################################################################ -->

<div fxLayout="column">
  <div *ngIf="hasExternalServiceLocationCallFinishedWithErrors" class="nested-column-12">
    <div fxLayout="row" fxLayoutAlign="center center" class="margin-top-60">
      <h3 id="claims-tracking-cannot-continue-for-the-following-reason-label">{{cannotContinueForTheFollowingReason}}</h3>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="margin-top-20">
      <label id="claims-tracking-error-message-label" class="eclaim-body-text-bold">{{externalServiceLocationApiErrorMessage}}</label>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="margin-top-20">
      <label id="claims-tracking-please-return-to-the-einsurance-page-label" class="eclaim-body-text">{{pleaseReturnToEInsurancePage}}</label>
    </div>

    <!-- Return to the eINSURANCE Page Button -->
    <div fxLayout="row" fxLayoutAlign="center center" class="margin-top-30">
      <button mat-flat-button
              class="mat-flat-button mat-primary"
              id="claims-tracking-return-to-legacy-einsurance-button"
              (click)="navigateToLegacyEInsurancePage()">{{buttonContent}}
      </button>
    </div>
  </div>
</div>

<!-- ################################################################################################################################################################################################################################################ -->
<!-- Claims Tracking Page -->
<!-- ################################################################################################################################################################################################################################################ -->

<div fxLayout="column" *ngIf="hasExternalServiceLocationCallFinished">
  <form novalidate [formGroup]="claimsTrackingForm">

    <!-- ############################################################################################################################################################################## -->
    <!-- Header Section -->
    <!-- ############################################################################################################################################################################## -->

    <label fxLayout="row" id="claims-tracking-track-claims-header" class="margin-bottom-20 eclaim-header-text">VSP Claim Tracking</label>

    <mat-card class="banner-card margin-bottom-20">
      <div fxLayout="row">
        <span class="banner-card-icon-span">
          <mat-icon class="banner-card-icon" svgIcon="eclaim-icons:infoCircleIcon">

          </mat-icon>
        </span>
        <span class="banner-card-text">
          <mat-label>
            If you need assistance or have questions regarding claims that have already been submitted,
          please call the provider claim support line at <strong>844.344.3591</strong>.
          </mat-label>
        </span>
      </div>
    </mat-card>


    <mat-card>
      <mat-card-header id="claims-tracking-card-header">
        <mat-card-title>
          <h1 id="claims-tracking-provider-claim-inquiry">Claim Search</h1>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">

        <!-- ############################################################################################################################################################################## -->
        <!-- Provider Information Section -->
        <!-- ############################################################################################################################################################################## -->

        <div class="nested-column-12 margin-bottom-20 margin-top-30 eclaim-body-text-bold">
          <label fxLayout="row" id="claims-tracking-provider-name-label">{{practiceName}}</label>
          <label fxLayout="row" id="claims-tracking-provider-street-label">{{practiceStreet}}</label>
          <label fxLayout="row" id="claims-tracking-provider-city-state-zip-label">{{practiceCityStateZip}}</label>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Paid/Priced Radio Buttons Section -->
        <!-- ############################################################################################################################################################################## -->

        <div fxLayout="row" >
          <label class="nested-column-4 eclaim-body-text margin-bottom-5" id="claims-tracking-claim-type-label">Claim Type</label>
        </div>
        <!-- Radio Buttons Section -->
        <div fxLayout="row" class="margin-bottom-20">
          <!-- Paid Claims -->
          <div class="nested-column-1_7">
            <mat-radio-group formControlName="searchByPaidClaimStatusRadioButton"
                             (change)="onPaidClaimStatusRadioButtonChange($event)">
              <!-- Search By Office Patient List Radio Button -->
              <mat-radio-button #searchByPaidClaimStatusRadioButton value="{{formStateYesNo.Yes}}"
                                id="claims-tracking-search-by-paid-claim-status-list-radio-button"
                                (keyup.enter)="onKeypressEventRadiobutton(searchByPaidClaimStatusRadioButton)">Paid Claims
                <mat-icon
                  id="claims-tracking-search-by-paid-claim-status-list-radio-button-info-icon"
                  class="help-circle"
                  matTooltip="Claims which VSP has paid and is sending or has sent payment."
                  svgIcon="eclaim-icons:helpCircle">
                </mat-icon></mat-radio-button>
            </mat-radio-group>
          </div>
          <!-- Priced Claims -->
          <div fxLayout="row" class="nested-column-1_7">
            <mat-radio-group formControlName="searchByPricedClaimStatusRadioButton"
                             (change)="onPricedClaimStatusRadioButtonChange($event)">
              <!-- Search By Office Patient List Radio Button -->
              <mat-radio-button #searchByPricedClaimStatusRadioButton value="{{formStateYesNo.Yes}}"
                                id="claims-tracking-search-by-priced-claim-status-list-radio-button"
                                (keyup.enter)="onKeypressEventRadiobutton(searchByPricedClaimStatusRadioButton)">Priced Claims
                <mat-icon
                  id="claims-tracking-search-by-priced-claim-status-list-radio-button-info-icon"
                  class="help-circle"
                  matTooltip="Claims which VSP has received and processed but has not sent payment."
                  svgIcon="eclaim-icons:helpCircle">
                </mat-icon></mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Search By Member Id or Claim Number Section -->
        <!-- ############################################################################################################################################################################## -->

        <!-- Search By Member Id -->
        <div fxLayout="row" [ngClass]="isPaidClaimSearch ? 'margin-bottom-20' : 'margin-bottom-30'">
          <div fxLayout="column" class="nested-column-3">
            <label class="eclaim-body-text margin-bottom-5" id="claims-tracking-member-id-label">Member ID (Optional)</label>
            <mat-form-field appearance="outline">
              <input matInput id="claims-tracking-search-by-member-id-field" formControlName="searchByMemberIdField" type="text"
                     class="form-control" (input)="onMemberIdOrClaimNumberInput()"/>
            </mat-form-field>
            <!-- Error Wrapper For The Search By Member Id Field -->
            <app-error-wrapper id="claims-tracking-search-by-member-id-field-error-message"
                               [config]="errorWrapperConfig.searchByMemberIdField"></app-error-wrapper>
          </div>

          <!-- OR Label -->
          <div fxLayout="column" class="nested-column-0_6">
            <label fxLayoutAlign="center center" class="or-text eclaim-body-text-bold" id="claims-tracking-or-label">OR</label>
          </div>

          <!-- Search By Claim Number -->
          <div fxLayout="column" class="nested-column-3">
            <label class="eclaim-body-text margin-bottom-5" id="claims-tracking-claim-number-label">Claim Number (Optional)</label>
            <mat-form-field appearance="outline">
              <input matInput maxlength="10" formControlName="searchByClaimNumberField"
                     id="claims-tracking-search-by-claim-number-field" (input)="onMemberIdOrClaimNumberInput()">
            </mat-form-field>
            <!-- Error Wrapper For The Search By Claim Number Field -->
            <app-error-wrapper id="claims-tracking-search-by-claim-number-field-error-message"
                               [config]="errorWrapperConfig.searchByClaimNumberField"></app-error-wrapper>
          </div>
        </div>

        <!-- Date of Service Dropdown -->
        <div *ngIf="isPaidClaimSearch" fxLayout="row" class="margin-bottom-30">
          <div fxLayout="column" class="nested-column-3">
            <label class="eclaim-body-text margin-bottom-5" id="claims-tracking-search-by-paid-claim-status-date-range-dropdown-label">Claim Paid Date</label>
            <select formControlName="claimPaidDateDropdown" matSelect class="custom-select" id="claims-tracking-search-by-paid-claim-status-date-range-dropdown">
              <option></option>
              <option *ngFor="let claimPaidDateDropdownValue of claimPaidDateDropdownValues" id="claims-tracking-search-by-paid-claim-status-date-range-dropdown-{{claimPaidDateDropdownValue.monthNumber}}"
                      [value]="claimPaidDateDropdownValue.monthDateRange">{{claimPaidDateDropdownValue.monthName}} {{claimPaidDateDropdownValue.monthYear}}</option>
            </select>
          </div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Row Divider -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-bottom-30">
          <mat-divider></mat-divider>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Reset and Search Button Section -->
        <!-- ############################################################################################################################################################################## -->

        <div fxLayout="row">
          <!-- Search Button -->
          <div fxLayout="column" class="margin-bottom-30 nested-column-1_4">
            <button mat-flat-button id="claims-tracking-search-button"
                    (click)="onSearchButtonClick()"
                    class="form-control search-button mat-flat-button mat-primary"
                    type="submit" [disabled]="!hasValidClaimsSearchCombination">Search</button>
          </div>
          <!-- Reset Button -->
          <div fxLayout="column" class="nested-column-1_4">
            <button id="claims-tracking-reset-button"
                    type="button"
                    (click)="resetForm()"
                    class="form-control clear-action-button">Clear</button>
          </div>
        </div>

      </mat-card-content>
    </mat-card>
  </form>

  <!-- ############################################################################################################################################################################## -->
  <!-- Search Results Section -->
  <!-- ############################################################################################################################################################################## -->

  <div *ngIf="hasSearchFinished" fxLayout="column">
<!--    Render Claims Tracking Search Results-->
    <app-claims-tracking-claims-tracking-search-results [claimSummaries]="claimSummaries"></app-claims-tracking-claims-tracking-search-results>

  </div>
</div>
